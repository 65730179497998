import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {BookingsSelector, IStateBooking} from '../../reducers/bookings.reducer';
import {BookingsActionsCreator} from './bookings.action';
import {BookingApiService} from '../../../services/api/booking.api.service';
import {AbstractCrudController} from '../abstractCrud.controller';
import {IApiFilter} from '../../../../../../common/interfaces/api';
import {ISelectors} from '../../reducers/abstract.reducer';
import {IAppState} from '../../store';
import {fromDateToEuFormat} from '../../../../../../common/moment-util';
import {BOOKING_TEMPLATE, END_DATE, EQ, ID, IS_NO_END, WITHOUT_END} from '../../../../../../common/constants';
import {IApiGetBooking} from '../../../../../../common/interfaces/booking';

@Injectable()
export class BookingsController extends AbstractCrudController<IStateBooking, IApiGetBooking> {

  constructor(apiService: BookingApiService, actionFactory: BookingsActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IStateBooking> = BookingsSelector;

  protected getApiAndFilters(isActive: boolean, getState: () => IAppState): IApiFilter[] {
    const filters = super.getApiAndFilters(isActive, getState);
    const preparedFilters = _.map(filters, (filter) => {
      const isTemplateId = filter.column === `${BOOKING_TEMPLATE}.${ID}`;
      return Object.assign({}, filter, isTemplateId ? {operator: EQ} : {});
    });

    const isWithWithoutEndFilter = _.reduce(preparedFilters, (acc: boolean, filter: IApiFilter) => {
      if (filter.column === END_DATE && _.includes(WITHOUT_END, filter.value)) {
        acc = true;
      }

      return acc;
    }, false);

    const newFilters = isWithWithoutEndFilter ? _.filter(preparedFilters, (filter) => filter.column !== END_DATE) : preparedFilters;
    newFilters.push(isWithWithoutEndFilter
      ? {column: IS_NO_END, operator: EQ, value: 'true'}
      : {column: END_DATE, operator: isActive ? 'gte' : 'lt', value: fromDateToEuFormat(new Date())});

    return newFilters;
  }
}
