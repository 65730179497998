import * as AngularCore from '@angular/core';
import {Compiler, ComponentFactoryResolver, ComponentRef, Injectable, Injector, NgModuleRef, Type} from '@angular/core';
import * as AngularCommon from '@angular/common';
import * as AngularRouter from '@angular/router';
import * as _ from 'lodash';
import * as AngularSvgIcon from 'angular-svg-icon';
import * as NgxSwiperWrapper from 'ngx-swiper-wrapper';
import * as platformBrowser from '@angular/platform-browser';
import {ɵSharedStylesHost as SharedStylesHost} from '@angular/platform-browser';
import * as Typed from 'typed.js/src/typed.js';

/**
 * This class is responsible for creating the actual angular component from the source code defined in the booking template.
 * It does so by defining the needed dependencies and then calling "eval" on the source code.
 *
 * If a booking template uses dependencies, which are NOT defined in modules in this class, it will not be able to display the booking
 * If you want to add a dependency, you'll first have to install it and then add it in here.
 */
@Injectable()
export default class ComponentCreator {

  constructor(
    private compiler: Compiler,
    private injector: Injector,
    private moduleRef: NgModuleRef<any>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private sharedStyleHost: SharedStylesHost,
  ) {
  }

  public createComponent(source: string | Type<any>): ComponentRef<any> {
    return _.isString(source) ? this.createComponentFromSource(source) : this.createComponentFromType(source);
  }

  private createComponentFromType(component: Type<any>): ComponentRef<any> {
    return this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
  }

  private createComponentFromSource(source: string): ComponentRef<any> {
    this.compiler.clearCache();
    // this will hold module exports. Will be filled after "eval" has run
    const exports = {};

    // this is the list of modules needed by the booking template
    const modules = {
      '@angular/core': AngularCore,
      '@angular/common': AngularCommon,
      '@angular/router': AngularRouter,
      '@angular/platform-browser': platformBrowser,
      'lodash': _,
      'angular-svg-icon': AngularSvgIcon,
      'ngx-swiper-wrapper': NgxSwiperWrapper,
      'typed.js/src/typed.js': Typed,
    };

    // create require function which is used in the booking template code
    // noinspection JSUnusedLocalSymbols
    const require: any = (module) => modules[module];

    console.debug(`component-creator - evaluating source`);
    // tslint:disable
    eval(source);

    // get the generated module key. There MUST only be one module, and it has to end with "Module"
    const [moduleKey] = Object.keys(exports).filter((key) => key.includes('Module'));
    const moduleWithComponentFactories = this.compiler.compileModuleAndAllComponentsSync(exports[moduleKey]);

    // get the generated component key. There MUST only be one component, and it has to end with "Component"
    const [componentKey] = Object.keys(exports).filter((key) => key.includes('Component'));
    // get the selector of that module, so we can get the correct factory
    let moduleSelector = null;

    try {
      moduleSelector = exports[componentKey].decorators[0].args[0].selector;
    } catch (e) {}

    // const factories = moduleWithComponentFactories.componentFactories;
    // find correct factory by module selector
    const factory = moduleWithComponentFactories.componentFactories.find((factory) => factory.selector === moduleSelector);

    const sharedStyleHost = this.sharedStyleHost as any;
    const startOfNewlyAddedStyles = Array.from(sharedStyleHost._styleNodes).length;

    // finally create the module
    const componentRef = factory.create(this.injector, [], null, this.moduleRef);

    // get styles which have been added through the creation of the component
    const newStyles: Element[] = Array.from<Element>(sharedStyleHost._styleNodes).slice(startOfNewlyAddedStyles);

    const oldDestroy = componentRef.destroy.bind(componentRef);
    componentRef.destroy = function destroyWithStyles() {
      // remove all styles associated with this componentRef
      newStyles.forEach(node => node.remove());
      return oldDestroy();
    };

    return componentRef;
  }
}
