import { IRuntime } from '../../../../../common/interfaces/location';
import {fromDateToHoursMinutes, timeStringToDate} from '../../../../../common/moment-util';
import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {IStateLocation} from '../../redux/reducers/locations.reducer';

@Injectable()
export class Runtimes {
  public getLocationModelData(currentLocation: IStateLocation, currentRuntimes) {
    return {
      external_id: currentLocation.external_id,
      active: currentLocation.active,
      name: currentLocation.name,
      width: currentLocation.width,
      height: currentLocation.height,
      city: currentLocation.city,
      country: currentLocation.country,
      timezone: currentLocation.timezone,
      zip: currentLocation.zip || null,
      street_and_number: currentLocation.street_and_number || null,
      runtimes: !currentRuntimes ? this.fixRunTimes(currentLocation.runtimes) : this.convertRunTimes(currentRuntimes) as IRuntime[],
      attributes: currentLocation.attributes || [{ key: '', value: '' }],
      comment: currentLocation.comment,
      ip: currentLocation.ip,
      last_seen: currentLocation.last_seen,
    };
  }

  public getLocationRuntimes(runtimes, currentRuntimes) {
    return !currentRuntimes ? this.fixRunTimes(runtimes) : this.convertRunTimes(currentRuntimes) as IRuntime[];
  }

  public fixRunTime(runTime: IRuntime) {
    const startTime = timeStringToDate(runTime.startTime) as any;
    const endTime = timeStringToDate(runTime.endTime) as any;
    return Object.assign({}, runTime, {startTime, endTime});
  }

  public fixRunTimes(runTimes: IRuntime[]) {
    return _.map(runTimes, this.fixRunTime);
  }

  public convertRuntime(runTime: IRuntime) {
    const startTime = typeof runTime.startTime === 'string' ? runTime.startTime : fromDateToHoursMinutes(runTime.startTime);
    const endTime = typeof runTime.endTime === 'string' ? runTime.endTime : fromDateToHoursMinutes(runTime.endTime);
    return Object.assign({}, runTime, {startTime, endTime});
  }

  public convertRunTimes(runTimes) {
    return _.map(runTimes, this.convertRuntime);
  }

}
