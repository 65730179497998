import {DOCUMENT} from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Inject,
  Injectable,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import * as _ from 'lodash';

import { IAppState } from '../../../../redux/store';
import { fromEvent, Subscription } from 'rxjs';
import { BookingsSelector, IStateBooking } from '../../../../redux/reducers/bookings.reducer';
import {  IStateLocation  } from '../../../../redux/reducers/locations.reducer';
import { FileApiService } from '../../../../services/api/file.api.service';
import { AuthApiService } from '../../../../services/api/auth.api.service';
import { LocationApiService } from '../../../../services/api/location.api.service';
import { BookingsController } from '../../../../redux/actions/bookings/bookings.controller';
import BookingRenderer from '../../../../renderer/booking-renderer';
import { ID } from '../../../../../../../common/constants';

@Component({
  selector: 'app-preview-location',
  templateUrl: './preview-location.component.html',
  styleUrls: ['./preview-location.component.scss'],
})
export class PreviewLocationComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private redux: NgRedux<IAppState>,
    private fileApiService: FileApiService,
    private authApiService: AuthApiService,
    private locationApiService: LocationApiService,
    private bookingsController: BookingsController,
    private bookingRenderer: BookingRenderer,
    private zone: NgZone,
  ) {}

  public static ROUTE = 'location';
  private templateKeysToSourceCode = {};
  private fileKeysToBlobUrls = {};
  private location: IStateLocation;
  private currentBookingsUpdateLoopTimeoutId: number;
  private updateContentContainer: boolean;
  private preparedBookings: IStateBooking[];

  private locationId: number;
  private lastUpdated = Date.now();

  private routeSubscription: Subscription;
  private resizeSubscription: Subscription;

  public isDestroyed = false;
  public isLoading = true;
  public isBooked = false;

  @ViewChild('contentContainer', {read: ViewContainerRef}) contentContainer: ViewContainerRef;
  @ViewChild('scaleableWrapper') scaleableWrapper: any;
  @ViewChild('scaleableContent') scaleableContent: any;

  private subscribeOnResize() {
    this.resizeSubscription = fromEvent(window, 'resize').subscribe(() => this.resizeContent());
    this.resizeContent();
  }

  private getPreparedBookings(shuffledBookingIds: number[]) {
    const allBookings: IStateBooking[] = BookingsSelector.getAll(this.redux.getState());
    const bookingsMap = _.chain(allBookings)
      .filter((booking) => !!_.find(booking.locations, [ID, this.locationId]))
      .keyBy(ID)
      .value();

    return _.compact(_.map(shuffledBookingIds, (bookingId) => bookingsMap[bookingId]));
  }

  private async getUpdatedBookings() {
    await this.redux.dispatch(this.bookingsController.updateActive());
    const shuffledBookings = await this.locationApiService.getPlayList(_.toString(this.locationId));

    return this.getPreparedBookings(shuffledBookings);
  }

  private resizeContent() {
    if (!this.scaleableWrapper || !this.scaleableContent) {
      return;
    }

    this.scaleableContent.nativeElement.style.width = `${this.location.width}px`;
    this.scaleableContent.nativeElement.style.height = `${this.location.height}px`;

    const {clientHeight, clientWidth} = this.scaleableWrapper.nativeElement;
    const {offsetHeight, offsetWidth} = this.scaleableContent.nativeElement;
    this.scaleableContent.nativeElement.style.transform = `scale(${Math.min(clientHeight / offsetHeight, clientWidth / offsetWidth)})`;
  }

  ngAfterViewChecked() {
    if (this.updateContentContainer && this.contentContainer) {
      this.updateContentContainer = false;
      this.bookingRenderer.setContentContainer(this.contentContainer);
      this.resizeContent();
    }
  }

  private async isUpdateNeeded() {
    const lastUpdate = _.parseInt(await this.locationApiService.getLastUpdated(_.toString(this.locationId)));
    if (lastUpdate <= this.lastUpdated) {
      return false;
    }

    this.lastUpdated = lastUpdate;
    return true;
  }

  private async updateFileKeys(bookings: IStateBooking[]) {
    const fileKeys = this.bookingRenderer.getAllFileKeys(bookings);
    for (const fileKey of fileKeys) {
      const key = await this.fileApiService.getFile(fileKey);
      this.fileKeysToBlobUrls[fileKey] = window.URL.createObjectURL(key);
    }
  }

  private async startBookingsUpdateLoop() {
    if (await this.isUpdateNeeded()) {
      this.location = await this.locationApiService.getExtendedLocation(this.locationId);
      const bookings = await this.getUpdatedBookings();

      await this.updateFileKeys(bookings);
      await this.bookingRenderer.update(bookings, this.location);

      this.updateContentContainer = true;
    }

    this.isBooked = this.bookingRenderer.getVisibleComponents().bookingsCount > 0;

    if (!this.isDestroyed) {
      this.zone.runOutsideAngular(() => {
        this.currentBookingsUpdateLoopTimeoutId = window.setTimeout(this.startBookingsUpdateLoop.bind(this), 1000) as any;
      });
    }
  }

  async ngAfterViewInit() {
    // @ts-ignore
    window.resetGlobalState();
    this.isLoading = true;

    const getAuthTokenCallback = this.authApiService.getAuthToken.bind(this.authApiService);
    this.bookingRenderer.setGetAuthTokenCallback(getAuthTokenCallback);
    this.bookingRenderer.setBookingsAlwaysVisible(false);

    await this.updateFileKeys(this.preparedBookings);

    for (const booking of this.preparedBookings) {
      const templateKey = booking.booking_template.template.key;
      this.templateKeysToSourceCode[templateKey] = await this.fileApiService.getFile(templateKey, 'text');
    }

    const getFileCallback = async (key: string) => this.fileKeysToBlobUrls[key];
    const getTemplateSourceCallback = (key: string) => this.templateKeysToSourceCode[key];

    this.location = await this.locationApiService.getExtendedLocation(this.locationId);
    await this.bookingRenderer.init(this.contentContainer, this.preparedBookings, this.location, getTemplateSourceCallback, getFileCallback);

    if (this.bookingRenderer.getVisibleComponents().bookingsCount === 0) {
      this.bookingRenderer.stop();
      if (this.bookingRenderer.getVisibleComponents().bookingsCount === 0) {
        this.bookingRenderer.pauseOrPlay();
      }
    }

    // noinspection JSIgnoredPromiseFromCall
    this.startBookingsUpdateLoop();

    this.subscribeOnResize();

    this.isLoading = false;
  }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'overlay');

    this.routeSubscription = this.route.params.subscribe(({id}) => this.locationId = _.parseInt(id));

    const shuffledBookingIds = _.get(this.route, 'snapshot.data.shuffledBookingIds', []);
    this.preparedBookings = this.getPreparedBookings(shuffledBookingIds);
    this.isBooked = this.preparedBookings.length > 0;
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.renderer.removeClass(this.document.body, 'overlay');

    this.bookingRenderer.destroy();

    window.clearTimeout(this.currentBookingsUpdateLoopTimeoutId);

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }
}

@Injectable()
export class PreviewLocationDataResolver implements Resolve<any> {
  constructor(
    private redux: NgRedux<IAppState>,
    private locationApiService: LocationApiService,
    private bookingsController: BookingsController,
  ) {
  }

  async resolve(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const results = await Promise.all([
      this.locationApiService.getPlayList(routeSnapshot.params.id),
      this.redux.dispatch(this.bookingsController.updateActive()),
    ]);

    return _.head(results);
  }
}
