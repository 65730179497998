import * as moment from 'moment';
import 'moment-timezone';

const TIME_FORMAT = 'HH:mm:ss';
const HOUR_MINUTE = 'HH:mm';

const EU_FORMAT = 'DD.MM.YYYY';
const MYSQL_DATE_FORMAT = 'YYYY-MM-DD';
const MYSQL_DATE_TIME_FORMAT = `${MYSQL_DATE_FORMAT} ${TIME_FORMAT}`;

export function getNowMysqlDate() {
  const now = moment();
  now.set({hour: 0, minute: 0, second: 0, millisecond: 0});

  return now.format(MYSQL_DATE_TIME_FORMAT);
}

export function formatDateForBackend(date?: string | null) {
  return !!date ? moment(new Date(date)).format(MYSQL_DATE_FORMAT) : null;
}

export function formatTimeForBackend(date?: string | null) {
  return !!date ? moment(new Date(date)).format(TIME_FORMAT) : null;
}

export function formatSeconds(seconds: number) {
  return moment.utc(seconds * 1000).format(TIME_FORMAT);
}

export function isAfterToday(date: string): boolean {
  if (!date) {
    return true;
  }

  const dateObj = moment(date, EU_FORMAT);
  const now = moment();

  return now < dateObj;
}

export function setDefaultTimeZone(timezoneId: string) {
  moment.tz.setDefault(timezoneId);
}

export function guessTimezone() {
  return moment.tz.guess();
}

function fromToFormatCreator(from: string, to: string, strict = true) {
  return (date: string) => {
    const formattedDate = moment(date, from, strict).format(to);
    return formattedDate === 'Invalid date' ? null : formattedDate;
  };
}

function fromFormatToDateCreator(from: string) {
  return (date: string) => moment(date, from).toDate();
}

function fromDateToFormatCreator(to: string) {
  return (date: Date | string) => moment(date).format(to);
}

export const fromDateToEuFormat = fromDateToFormatCreator(EU_FORMAT);
export const fromDateToHoursMinutes = fromDateToFormatCreator(HOUR_MINUTE);

export const fromEuToMysqlLikeDayMonth = fromToFormatCreator('DD.MM', 'MM-DD', false);
export const fromEuToMysqlLikeMonthYear = fromToFormatCreator('MM.YYYY', 'YYYY-MM', false);

export const fromMySqlToEuFormat = fromToFormatCreator(MYSQL_DATE_FORMAT, EU_FORMAT);
export const fromFullEuToMySqlFormat = fromToFormatCreator(EU_FORMAT, MYSQL_DATE_FORMAT);
export const formatTime = fromToFormatCreator(TIME_FORMAT, HOUR_MINUTE, false);

export const dateStringToDate = fromFormatToDateCreator(EU_FORMAT);
export const timeStringToDate = fromFormatToDateCreator(TIME_FORMAT);
